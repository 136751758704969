import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { Checkbox, message, Modal } from "antd";

import { ShopClient } from "../../../entity/shopClient.entity";
import { request } from "../../../libs/api";

const shopRoles = [
  "shop",
  "shop_admin",
  "kiosk",
  "kd",
  "regi",
  "handy",
  "kiosk-2nd",
  "self-checkout",
  "wait",
];

const CheckboxContainer = styled.div`
  margin-bottom: 16px;
`;

type Props = {
  shopClient: ShopClient;
  isOpen: boolean;
  onClose: () => void;
};

export const SetShopClientClaimsDialog = memo<Props>(({ shopClient, isOpen, onClose }: Props) => {
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    setRoles(shopClient.roles);
  }, [shopClient]);

  const toggleRole = (role: string) => {
    if (roles.includes(role)) {
      setRoles(roles.filter((r) => r !== role));
    } else {
      setRoles([...roles, role]);
    }
  };

  const setClaim = () => {
    if (roles.length === 0) return message.error("少なくとも一つ以上の Role を選択してください");

    request
      .post(`shops/${shopClient.shopId}/shop_clients/${shopClient.shopClientId}/role`, {
        roles,
        companyId: shopClient.companyId,
      })
      .then(() => {
        message.success("更新しました");
        onClose();
      })
      .catch((err) => {
        console.error(err);
        message.error("更新に失敗しました");
      });
  };

  return (
    <Modal title={shopClient.email} open={isOpen} onOk={setClaim} okText="更新" onCancel={onClose}>
      {shopRoles.map((role) => (
        <CheckboxContainer key={role}>
          <Checkbox checked={roles.includes(role)} onChange={() => toggleRole(role)}>
            {role}
          </Checkbox>
        </CheckboxContainer>
      ))}
    </Modal>
  );
});
