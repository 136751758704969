import React, { memo, useMemo, useState } from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import dayjs from "dayjs";
import { collection, doc } from "firebase/firestore";

import { MainLayout } from "components/Layout/MainLayout";
import { LoadMoreButton } from "components/LoadMoreButton";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { useSearchParams } from "hooks/useSearchParams";
import { useTabletDeviceRemoteManagement } from "hooks/useTabletDeviceRemoteManagement";
import { firestore } from "libs/firebase";
import {
  CashRegisterStatusTable,
  CashRegisterStatusType,
} from "pages/CashRegisterStatus/CashRegisterStatusTable";
import { useCashRegisterStatusQuery } from "pages/CashRegisterStatus/queries.firestore";

import { CashRegisterOfflineAvailability } from "./CashRegisterOfflineAvailability";
import { CashRegisterSettingsDialog } from "./CashRegisterSettingsDialog";
import { OneTimeCodeGenerator } from "./OneTimeCodeGenerator";
import { useCashRegisterStatusGetShopClientsQuery } from "./queries";

type ServiceWideConfig = {
  disableStandaloneOperationSupport?: boolean;
  disableEnhancedOfflineSupport?: boolean;
};

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CashRegisterStatus = memo(() => {
  const { getSearchParam } = useSearchParams<"shopId">();

  const [selectedShopClientId, setSelectedShopClientId] = useState<string | null>(null);
  const shopId = getSearchParam("shopId");

  const [serviceWideConfig = {}] = useDocumentDataOnce(
    doc<ServiceWideConfig>(collection(firestore, "serviceWideConfig"), "0"),
  );

  const { data } = useCashRegisterStatusGetShopClientsQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );

  const shopClientIdToShopClientMap = useMemo(
    () =>
      new Map((data?.shopClient ?? []).map((shopClient) => [shopClient.shopClientId, shopClient])),
    [data?.shopClient],
  );

  const {
    loading,
    data: statuses = [],
    loadMore,
    isLast,
  } = useCashRegisterStatusQuery(shopId ? { variables: { shopId } } : { skip: true });

  const shopClientIds = useMemo(() => statuses.map(({ shopClientId }) => shopClientId), [statuses]);

  const { cashRegisterSettings, fetchCashRegisterRemoteManagement } =
    useTabletDeviceRemoteManagement({
      shopClientIds,
    });

  const rowItems = useMemo(
    () =>
      statuses.map<CashRegisterStatusType>((status) => {
        const { shopClientId } = status;
        const shopClient = shopClientIdToShopClientMap.get(shopClientId);
        return {
          shopClientId,
          deviceId: status.deviceId,
          uid: status.uid,
          shopClientName: shopClient?.name ?? "不明",
          printerName: cashRegisterSettings[shopClientId]?.printerConnectionName ?? null,
          printerTarget: cashRegisterSettings[shopClientId]?.printerConnectionTarget ?? null,
          cashChangerTarget:
            cashRegisterSettings[shopClientId]?.cashChangerConnectionTarget ?? null,
          disableStandaloneOperationSupport:
            cashRegisterSettings[shopClientId]?.disableStandaloneOperationSupport ??
            serviceWideConfig.disableStandaloneOperationSupport ??
            null,
          nativeAppVersion: status.nativeAppVersion,
          version: status.version,
          osName: status.osName,
          osVersion: status.osVersion,
          updateId: status.updateId,
          updateCreatedAt:
            status.updateCreatedAt &&
            dayjs(status.updateCreatedAt.toDate()).format("YYYY/MM/DD HH:mm:ss"),
          updatedAt: dayjs(status.updatedAt.toDate()).format("YYYY/MM/DD HH:mm:ss"),
        };
      }),
    [
      cashRegisterSettings,
      serviceWideConfig.disableStandaloneOperationSupport,
      shopClientIdToShopClientMap,
      statuses,
    ],
  );

  return (
    <MainLayout title="レジ状況">
      <ShopSelector />

      <Spacer size={16} />

      <Columns>
        <LoadMoreButton loadMore={loadMore} isLast={isLast} />
        <Spacer inline size={16} />
        <OneTimeCodeGenerator shopId={shopId ?? null} />
      </Columns>

      <Spacer size={20} />
      {shopId && (
        <>
          <CashRegisterOfflineAvailability shopId={shopId} />
          <Spacer size={20} />
        </>
      )}

      <CashRegisterStatusTable
        loading={loading}
        cashRegisterStatuses={rowItems}
        onEdit={({ shopClientId }) => setSelectedShopClientId(shopClientId)}
      />

      <CashRegisterSettingsDialog
        shopClientId={selectedShopClientId}
        settings={selectedShopClientId ? cashRegisterSettings[selectedShopClientId] : undefined}
        onClose={() => {
          if (selectedShopClientId) fetchCashRegisterRemoteManagement(selectedShopClientId);
          setSelectedShopClientId(null);
        }}
      />
    </MainLayout>
  );
});
