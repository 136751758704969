export type ProductEnv =
  | "prod"
  | "beta"
  | "stag"
  | "develop"
  | "discovery-staging"
  | "discovery"
  | `local:${string}`;

export type ProductInfo = {
  env: ProductEnv;
  name: string;
  isLocalhostProxy: boolean;
};

export const parseProductInfoFromUrl = (url: string): ProductInfo | null => {
  const { hostname, protocol } = new URL(url);

  if (protocol !== "https:") return null;
  if (!hostname.endsWith("self.dinii.jp")) return null;

  const isLocalhostProxy = hostname.endsWith("localhost.self.dinii.jp");

  const [name, env] = hostname.split(".");
  if (!name || !env) return null;

  switch (env) {
    case "self":
      return { env: "prod", name, isLocalhostProxy };
    case "beta":
      return { env: "beta", name, isLocalhostProxy };
    case "stag":
      return { env: "stag", name, isLocalhostProxy };
    case "develop":
      return { env: "develop", name, isLocalhostProxy };
    case "discovery-stag":
      return { env: "discovery-staging", name, isLocalhostProxy };
    case "discovery":
      return { env: "discovery", name, isLocalhostProxy };
    default:
      return isLocalhostProxy ? { env: `local:${env}`, name, isLocalhostProxy } : null;
  }
};
