import { collection, CollectionReference, orderBy, Timestamp, where } from "firebase/firestore";

import { useFirestorePagination } from "hooks/useFirestorePagination";
import { firestore } from "libs/firebase";

type CashRegisterStatusType = {
  deviceId: string;
  uid: string;
  shopId: string;
  shopClientId: string;
  name: string;
  version: string;
  nativeAppVersion: string;
  osName: string;
  osVersion: string;
  updateId?: string;
  updateCreatedAt?: Timestamp;
  updatedAt: Timestamp;
};

export const useCashRegisterStatusQuery = ({
  variables,
  skip,
}: {
  variables?: {
    shopId: string;
  };
  skip?: boolean;
}) =>
  useFirestorePagination<CashRegisterStatusType>(
    () => ({
      skip: Boolean(skip),
      collection: collection(
        firestore,
        "tabletDeviceStatus",
      ) as CollectionReference<CashRegisterStatusType>,
      constraints: variables?.shopId
        ? [where("shopId", "==", variables.shopId), orderBy("updatedAt", "desc")]
        : null,
      getKey: ({ deviceId, uid }) => `${uid}-${deviceId}`,
    }),
    [skip, variables?.shopId],
  );
